<template>
  <div class="m-0 p-0 w-100">
    <div class="row w-100" style="font-weight: 900">
      <div class="col">
        <h5>Dashboard</h5>
      </div>
      <div class="col text-right" style="color: var(--color-primary)">
        <div class="d-flex justify-content-end align-items-center">
          <i class="lni lni-spinner-arrow ml-2" style="font-weight: bold"></i>
          <p style="margin: 0 0 0 10px; cursor: pointer" @click="getInfos">
            Recarregar página
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="m-0 p-0 w-100 blocos">
    <div class="row">
      <div class="col bloco" style="margin-right: 15px">
        <div class="row">
          <div class="col especiB">
            <i
              class="lni lni-users iconDash"
              style="color: var(--color-primary)"
            ></i>
            <p class="blocoContent">Usuários</p>
          </div>
          <div class="col">
            <h2 id="usersCount" class="blocoContent numberBloco">
              {{ users }}
            </h2>
          </div>
        </div>
      </div>
   
      <div class="col bloco colPlayers" style="margin-left: 15px">
        <div class="row">
          <div class="col especiB">
            <i
              class="lni lni-game iconDash"
              style="color: var(--color-primary)"
            ></i>
            <p class="blocoContent">Players Ativos</p>
          </div>
          <div class="col">
            <h2 id="playersCount" class="blocoContent numberBloco">
              {{ players }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col bloco graph"
        style="width: 450px; height: 380px; margin: 0 10px 20px 0"
      >
        <p
          class="blocoContent"
          style="text-align: center; margin-top: 20px; margin-bottom: 20px"
        >
          Jogos mais acessados
        </p>
        <GamesGraph v-if="games.length > 0" :games="games"></GamesGraph>
      </div>
      <div
        class="col bloco graph"
        style="width: 450px; height: 380px; margin: 0 10px 20px 0"
      >
        <p
          class="blocoContent"
          style="text-align: center; margin-top: 20px; margin-bottom: 20px"
        >
          Provedores mais acessados
        </p>

        <ProviderGraph
          v-if="providers.length > 0"
          :provider="providers"
        ></ProviderGraph>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toast-notification";
import GamesGraph from "./SubHome/GamesGraph.vue";
import ProviderGraph from "./SubHome/ProviderGraph.vue";

export default {
  name: "HomePage",
  components: {
    GamesGraph,
    ProviderGraph,
  },
  data() {
    return {
      users: 0,
      players: 0,
      games: [],
      providers: [],
      toast: null,
    };
  },
  async mounted() {
    await this.getInfos();
    document.addEventListener("click", this.handleClickOutside);
    this.toast = useToast({ position: "top-right" });
  },
  methods: {
    async getInfos() {
      var token = localStorage.getItem("jwt-token");
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/agentes/home", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          this.users = data.data.user_count;
          this.players = data.data.active_players;
          this.games = data.data.games_views || [];
          this.providers = data.data.provedores_views || [];
        })
        .catch(() => {
          
          this.toast.error(
            "Ocorreu um erro interno ao recuperar suas informações"
          );
        });
    },
  },
};
</script>
<style>
.bloco {
  background-color: white;
  border-radius: 10px;
}

.blocos .row {
  margin: 30px 0;
}

.iconDash {
  font-size: 2.5rem;
}

.blocoContent {
  margin: 0;
  font-weight: 900;
}

.numberBloco {
  text-align: right;
  padding-top: 15px;
}

.descBloco {
  text-align: right;
}
@media (max-width: 770px) {
  .numberBloco {
    display: block;
    text-align: center !important;
  }
  .blocoContent {
    text-align: center !important;
    margin-top: 20px;
  }
  .especiB {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bloco {
    margin: 10px !important;
  }
  .graph {
    margin: 0 0 20px 0 !important;
  }
  .btnsEstado {
    display: none !important;
  }
}
</style>
