<template>
  <div
    class="modal fade"
    id="createIpModal"
     ref="createIpModal"
    tabindex="-1"
    aria-labelledby="createIpModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createIpModalLabel">
            Cadastrar um novo Ip
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col">
              <label for="createPasswordInput" class="form-label">IP</label>
              <input
                type="text"
                v-model="ip"
                class="form-control"
                id="createIpAddress"
                placeholder="Digite o endereço IP"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn"
            style="background-color: var(--color-primary); color: white"
            id="createIpButton"
            @click="create"
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useToast } from 'vue-toast-notification';


export default {
  name: "CreateNewIp",
  data() {
    return {
        ip: "",
        toast: null
    }
  },
  mounted() {
    this.toast = useToast({ position: "top-right" });

  },
  methods: {
    async create(){
        var token = localStorage.getItem("jwt-token");
        await axios
            .post(process.env.VUE_APP_API_URL + "/api/agentes/ip", 
               
                { 
                    ip: this.ip
                },
                {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            )
            .then(() => {
                this.ip = "";
                this.toast.success("Ip cadastrado com sucesso");
                this.$emit('getInfos');
                
            })
            .catch((error) => {
            console.log(error);
            this.toast.error(
               error?.response?.data?.msg || "Ocorreu um erro interno ao recuperar suas informações"
            );
            });
        }
  }
};
</script>
