<template>
  <div class=" top-menu align-items-center flex-row d-flex">
    <div class="d-flex topMenuTopSI">
      <p style="margin-right: 20px; margin-bottom: 0">Saldo: R${{ balance }}</p>
    </div>
    <div class="col topMenuTopSI" id="colUser">
      <div class="d-flex align-items-center justify-content-end">
        

        <div class="mr-3" style="margin-right: 15px">
          <p style="margin: 0">{{ name }}</p>
        </div>
        <div>
          <img
            src="../../assets/img/profile.png"
            alt=""
            id="profileIcon"
            style="max-width: 55px"
          />
        </div>
      </div>
      <div v-if="showTooltip" id="menuOpen" class="editp">
        
        <div @click="logout" class="divItens">
          <i class="lni lni-exit"></i>
          <span>Sair</span>
        </div>
      </div>
    </div>
  </div>
  <hr class="hr" />
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toast-notification";

export default {
  name: "TopMenu",
  components: {
  },
  data() {
    return {
      balance: 0,
      subBalance: 0,
      currency: "",
      name: "",
      showTooltip: false,
      toast: null,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.toast = useToast({ position: "top-right" });
    this.getInfos();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    async getInfos() {
      var token = localStorage.getItem("jwt-token");
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/agentes/auth/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          let limite = 25;
          var name = data.data.name;
          if (name.length > limite) {
            name = name.substring(0, limite) + "...";
          }
          this.name = name;
          this.balance = data.data.balance;
        })
        .catch(() => {
          this.toast.error(
            "Ocorreu um erro interno ao recuperar suas informaçõess"
          );
        });
    },
    logout() {
      localStorage.removeItem("jwt-token");
      window.location.reload();
    },
    handleClickOutside(event) {
      if (
        this.showTooltip &&
        event.target.id != "menuOpen" &&
        event.target.id != "profileIcon"
      ) {
        this.showTooltip = false;
      } else if (event.target.id == "profileIcon") {
        this.showTooltip = true;
      }
    },
    handleTooltipClick(event) {
      event.stopPropagation();
    },
  },
};
</script>
<style>
p {
  font-size: 14px;
  font-weight: 600;
}
.linha {
  margin: 10px;
}
.divItens {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.editp {
  z-index: 1000;
  border-radius: 3px 15px 3px 3px;
  width: 162px;
  background: var(--color-primary);
  position: absolute;
  right: 21px;
  padding: 10px;
  top: 82px;
}
.divItens:hover {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgb(255 255 255 / 0.08);
}
@media (max-width: 650px) {
  .top-menu {
    display: none !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
  .hr {
    display: none !important;
  }
  .topMenuTopSI {
    height: 0px;
    width: 0px;
    display: none !important;
  }
}
</style>
