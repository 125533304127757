<template>

  <div
    class="d-flex"
    style="background-color: var(--color-primary); width: 100%"
  >

    <SideBar v-if="loggedIn" />
    <div class="divS p-4" v-if="loggedIn">
      <TopMenu />
      <router-view></router-view>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import SideBar from "./components/Nav/SideBar.vue";
import TopMenu from "./components/Nav/TopMenu.vue";
import router from "./router";

export default {
  name: "App",
  components: {
    SideBar,
    TopMenu,
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  setup() {
    const router = useRouter();
    router.beforeEach((to) => {
      document.title = to.name || process.env.VUE_APP_NAME;
    });
    return {};
  },
  async mounted() {
    var token = localStorage.getItem("jwt-token");
    
    if (token != null) {
      this.loggedIn = true;
    } else{
      router.push("/login");
    }
  },
};
</script>
<style lang="scss">
@import "~bootstrap/scss/bootstrap";
.divS {
  background-color: #eff2f9;
  width: 100%;
  border-top-left-radius: 50px;
  overflow: auto;
}
@media (max-width: 650px) {
  .divS {
    border-top-left-radius: 0;
    padding-top: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .topMenu {
    display: none;
  }
}
</style>
