<template>
  <div class="m-0 p-0 w-100">
    <div class="row w-100" style="font-weight: 900">
      <div class="col">
        <h5>Gestão de usuários</h5>
      </div>
      <div class="col text-right" style="color: var(--color-primary)">
        <div
          @click="getInfos"
          class="d-flex justify-content-end align-items-center"
        >
          <i class="lni lni-spinner-arrow ml-2" style="font-weight: bold"></i>
          <p style="margin: 0 0 0 10px">Recarregar página</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4 p-0 w-100 bloco">
    <div class="row p-5">
      <div class="container">
        <div class="d-flex-between alterarClasse">
          <p class="m-0 flex-grow-1" id="allAgents" style="width: 100px">
            Total de Players: ({{ players.length }})
          </p>
        </div>
      </div>
      <hr class="mt-4 mb-4" />
      <div class="d-flex justify-content-end align-items-center p-0">
       

        <div style="margin-left: 10px;" class="searchItem">
          <input
            type="text"
            @input="pesquisar"
            v-model="search"
            id="buscaItem"
            placeholder="Pesquisar..."
          />
        </div>
      </div>
      <div v-if="players.length != 0" class="table-responsive p-0 mt-4">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Saldo</th>
              <th>Valor Apostado</th>
              <th>Valor Ganho</th>
              <th>RTP</th>
              <th>Influencer</th>
              <th>Token</th>
              <th>Código do agente</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in players" :key="player.username">
              <td>{{ player.username }}</td>
              <td>{{ player.saldo }}</td>
              <td>{{ player.valor_debitado }}</td>
              <td>{{ player.valor_ganho }}</td>
              <td>
                <input
                  @change="update(player)"
                  style="border: 0; text-align: center"
                  type="text"
                  v-model="player.rtp"
                />
              </td>
              <td>
                <input
                  class="form-check-input"
                  @change="update(player)"
                  type="checkbox"
                  :true-value="1"
                  :false-value="0"
                  v-model="player.is_influencer"
                />
              </td>
              <td>{{ player.token }}</td>
              <td>{{ player.agentCode }}</td>
              <td>{{ player.status.toUpperCase() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="display: flex; justify-content: center;" v-else>
              <div  class="alert alert-warning">Nenhum player encontrado</div>
      </div>
      <Paginator
        :first="1"
        v-if="players.length != 0"
        :currentPage="current_page"
        :rows="per_page"
        :totalRecords="total"
        @page="fetchData"
      ></Paginator>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toast-notification";
import Paginator from "primevue/paginator";

export default {
  name: "PlayersPage",
  components: {
    Paginator
  },
  data() {
    return {
      current_page: 1,
      per_page: 10,
      total: 10,
      next: null,
      players: [],
      search: "",
    };
  },
  async mounted() {
    this.toast = useToast({ position: "top-right" });
    await this.getInfos();
  },
  methods: {
    pesquisar() {
      this.getInfos(1, this.search);
    },
    async update(player) {
      const token = localStorage.getItem("jwt-token");
      await axios
        .put(
          `${process.env.VUE_APP_API_URL}/api/agentes/player`,
          {
            id: player.id,
            rtp: player.rtp,
            influencer: player.is_influencer,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.toast.success("Player atualizado com sucesso");

          this.getInfos();
        })
        .catch((error) => {
          this.toast.error(
            error?.response?.data?.msg || "Ocorreu um erro interno"
          );
          this.getInfos();
        });
    },
    async handleChange(selectedValues) {
      this.selecionado = selectedValues;
      this.getInfos();
    },
    async getInfos(page = 1, search = "") {
      const token = localStorage.getItem("jwt-token");
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/agentes/player?page=${page}&search=${search}&agent=${JSON.stringify(this.selecionado)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.next = response.data.next_page_url;
          this.current_page = response.data.current_page - 1;
          this.per_page = response.data.per_page;
          this.total = response.data.total;
          this.players = response.data.data;
         
        })
        .catch((error) => {
          console.log(error)
          this.toast.error(
            "Ocorreu um erro interno ao recuperar suas informações"
          );
        });
    },
    fetchData(page) {
      var pageA = page.page + 1;
      this.getInfos(pageA);
    },
  },
};
</script>
