<template>

<div class="m-0 p-0 w-100">
    <div class="row w-100" style="font-weight: 900">
        <div class="col">
            <h5>Documentação da API</h5>
        </div>
        <div class="col text-right" style="color: #064d3b">
            
        </div>
    </div>
</div>
<div class="mt-4 p-0 w-100 bloco">
    <div class="row p-5">
        <div class="d-flex justify-content-between align-items-center">
            <p class="m-0 flex-grow-1" style="width: 100px">Lista da API</p>
        </div>
        <hr class="mt-4 mb-4">
       <div class="w-100">
            <label for="linkApi">API endpoint:</label>
            <input type="text" readonly class="input w-full border ml-8 flex-1" v-model="linkApi" style="width: 80%; padding: 5px; border-radius: 8px; margin-left: 20px; color: red;">
       </div>
       <div class="listItens">
            <div class="apiItem">
                <div class="apiHeaderItem"><b>1. Abrir o jogo</b></div>
                <div class="apiBodyItem">
                    <div class="requisicao">
                        <p>Ex: Requisição</p>
                        <p class="linkApiC"><span class="typeApi">POST</span>{{ linkApi }}/api/v2/game_launch</p>
                        <pre><code v-html="highlightedOpenGame"></code></pre>


                    </div>
                    <div class="response">
                        <p style="margin-bottom: 73px;">Ex: Resposta </p>
                        <pre><code v-html="highlightedOpenGameResponse"></code></pre>

                    </div>
                </div>
            </div>
            <!-- Saldo -->
            <div class="apiItem">
                <div class="apiHeaderItem"> <b>2. Saldo do jogador <span class="aviso">(A API enviará uma solicitação POST para o seu cassino para obter o saldo do jogador)</span></b></div>
                <div class="apiBodyItem">
                    <div class="requisicao">
                        <p>Ex: Requisição</p>
                        <p class="linkApiC"><span class="typeApi">POST</span>https://webhookDoSeuCassino.com/playfiver/webhook</p>
                        <pre><code v-html="highlightedGetBalance"></code></pre>
                    </div>
                    <div class="response">
                        <p style="margin-bottom: 73px;">Ex: Resposta </p>
                        <pre><code  v-html="highlightedGetBalanceResponse"></code></pre>
                    </div>
                </div>
            </div>

             <!-- Aposta -->
             <div class="apiItem">
                <div class="apiHeaderItem"> <b>2. Transações <span class="aviso">(A API enviará uma solicitação POST para o seu cassino para informar uma transação feita por um jogador)</span></b></div>
                <div class="apiBodyItem">
                    <div class="requisicao">
                        <p>Ex: Requisição</p>
                        <p class="linkApiC"><span class="typeApi">POST</span>https://webhookDoSeuCassino.com/playfiver/webhook</p>
                        <pre><code v-html="highlightedPostTransaction"></code></pre>
                    </div>
                    <div class="response">
                        <p style="margin-bottom: 73px;">Ex: Resposta </p>
                        <pre><code  v-html="highlightedPostTransactionResponse"></code></pre>
                    </div>
                </div>
            </div>
           
       </div>
    </div>
</div>
</template>
<script>
    import axios from 'axios';
    import hljs from 'highlight.js';
    import { useToast } from 'vue-toast-notification';
    export default {
        name: "DocumentationPage",
        data() {
            return {
                linkApi: process.env.VUE_APP_API_URL,
                agentes: {
                    "agent_code": '0000',
                    "agent_secret": '0000',
                    "agent_token": '0000',

                },
                toast: null
            }
        },
        computed: {
            highlightedOpenGame() {
                const json = `{
    "agentToken": "${this.agentes.agent_token}",
    "secretKey": "${this.agentes.agent_secret}",
    "user_code": "testPlayer",
    "game_code": 126,
    "user_balance": 20
}`;
                return hljs.highlight(json, { language: 'json', ignoreIllegals: true }).value;
            },
            highlightedOpenGameResponse() {
                const json = `{
    "status": 1,
    "msg": "SUCCESS",
    "launch_url": "${this.linkApi}/....",
    "user_code": "testPlayer",
    "user_balance": 20,
    "user_created": false,
    "name": "Fortune Tiger"
}`;
                return hljs.highlight(json, { language: 'json', ignoreIllegals: true }).value;
            },
            highlightedGetBalance() {
                const json = `{
    "type": "BALANCE",
    "user_code": "testPlayer"
}`;
                return hljs.highlight(json, { language: 'json', ignoreIllegals: true }).value;
            },
            highlightedGetBalanceResponse() {
                const json = `// Uma resposta de sucesso:
{
    "msg": "",
    "balance": 100
}
// Uma resposta de error:
{
    "msg": "INVALID_USER",
    "balance": 0
}    
`;
                return hljs.highlight(json, { language: 'json', ignoreIllegals: true }).value;
            },
            highlightedPostTransaction() {
                const json = `{
    "type": "WinBet",
    "agent_code": "${this.agentes.agent_code}",
    "agent_secret": "${this.agentes.agent_secret}",
    "user_code": "testPlayer",
    "user_balance": 20,
    "game_type": "slot", // Tipo de jogo(Slots, Live, etc)
    "slot": {
        "provider_code": "PGSOFT",
        "game_code": 126,
        "round_id": "12345",
        "bet": 20, // Valor apostado 
        "win": 0, // Valor ganho
        "txn_id": "1223sder321", 
        "txn_type": "debit_credit",
        "user_before_balance": 20,
        "user_after_balance": 0,
        "created_at": "2024-08-10"
    }
}`
                return hljs.highlight(json, { language: 'json', ignoreIllegals: false }).value;
            },
            highlightedPostTransactionResponse() {
                const json = `// Uma resposta de sucesso:
{
    "msg": "",
    "balance": 100
}
// Uma resposta de error:
{
    "msg": "INVALID_USER" // Ou INSUFFICIENT_USER_FUNDS,
    "balance": 0
}    
`;
                return hljs.highlight(json, { language: 'json', ignoreIllegals: false }).value;
            },
        },
        mounted() {
            this.$nextTick(() => {
                hljs.highlightAll();
            });
            this.toast = useToast({ position: "top-right" });
            this.getInfos();
        },
        methods: {
            async getInfos() {
                const token = localStorage.getItem("jwt-token");
                await axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/agentes/documentation`,
                    {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    }
                )
                .then((response) => {
                    this.agentes = response.data
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                    this.toast.error(
                    "Ocorreu um erro interno ao recuperar suas informações"
                    );
                });
            },
        }
    }

</script>
<style>

.apiItem{
    background-color: var(--color-primary-clear);
    padding: 20px;
    color: var(--color-text);
    border-radius: 10px;
    margin-top: 15px;
}
.aviso {
    color: red;
    font-size: 12px;
}
.requisicao {
    width: 450px;
    max-width: 100%;
}
.response {
    width: 344px;
    max-width: 100%;
}
.apiHeaderItem {
    cursor: pointer;
}

.apiBodyItem{
    flex-wrap: wrap;
    display: flex;
    padding-top: 10px;
    column-gap: 40px;
}

.jsonContainer {
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    white-space: pre-wrap;
    font-family: 'Courier New', Courier, monospace;
}

.copyButton, .typeApi {
    background-color: var(--color-primary);
    color: white;
    border: none;
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
}

.typeApi{
    margin-right: 20px;
}
.linkApiC {
    color: red !important;
    word-break: break-word;
}

</style>