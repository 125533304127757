import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./components/Pages/HomePage.vue";
import PlayersPage from "./components/Pages/PlayersPage.vue";

import IpWhitelist from "./components/Pages/IpWhitelist.vue";
import LoginView from "./view/LoginView.vue";
import AuthLayout from "./layout/AuthLayout.vue";
import axios from "axios";
import TransactionPage from "./components/Pages/TransactionPage.vue";
import DocumentationPage from "./components/Pages/DocumentationPage.vue";
import GamesPage from "./components/Pages/GamesPage.vue";

const routes = [
  
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
 
  {
    path: "/documentation",
    name: "Documentação",
    component: DocumentationPage,
  },
  {
    path: "/games",
    name: "Jogos",
    component: GamesPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { layout: AuthLayout },
  },

  {
    path: "/ip",
    name: "Ip Whitelist",
    component: IpWhitelist,
  },
 
  {
    path: "/players",
    name: "Players",
    component: PlayersPage,
  },
  {
    path: "/transaction",
    name: "Transações",
    component: TransactionPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function verifyJWT(token) {
  await axios
    .get(process.env.VUE_APP_API_URL + "/api/agentes/auth/verify", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .catch(() => {
      localStorage.removeItem("jwt-token");
      window.location.reload();
    });
}

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("jwt-token");
  if (token) {
    await verifyJWT(token);
  }
  next();
});

export default router;
