<template>
    <div class="m-0 p-0 w-100">
      <div class="row w-100" style="font-weight: 900">
        <div class="col">
          <h5>Gestão de Transações</h5>
        </div>
        <div class="col text-right" style="color:var(--color-primary)">
          <div
            @click="getInfos"
            class="d-flex justify-content-end align-items-center"
          >
            <i class="lni lni-spinner-arrow ml-2" style="font-weight: bold"></i>
            <p style="margin: 0 0 0 10px">Recarregar página</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 p-0 w-100 bloco">
      <div class="row p-5">
        <div class="container">
          <div class="d-flex-between alterarClasse">
            <p class="m-0 flex-grow-1" id="allTransaction" style="width: 100px">
              Total de Transações: ({{ transaction.length }})
            </p>
           
          </div>
        </div>
        <hr class="mt-4 mb-4" />
        <div  class="d-flex justify-content-end align-items-center p-0">
  

          <div style="margin-left: 10px;" class="searchItem">
            <input
              type="text"
              @input="pesquisar"
              v-model="search"
              id="buscaItem"
              placeholder="Pesquisar..."
            />
          </div>
        </div>
        <div v-if="transaction.length != 0" class="table-responsive p-0 mt-4">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Usuário</th>
                <th>Agente</th>
                <th>Valor</th>
                <th>Tipo</th>
                <th>Status</th>
                <th>Influencer</th>
                <th>Reembolsada</th>
                <th>RTP do usuário</th>
                <th>RTP do agente</th>
                <th>Data Criação</th>

              </tr>
            </thead>
            
            <tbody>
            
              <tr v-for="transaction in transaction" :key="transaction.id">
                <td>{{ transaction.user }}</td>
                <td>{{ transaction.agente }}</td>
                <td>{{ transaction.valor }}</td>
                <td>{{ transaction.tipo }}</td>
                <td>
                    <input
                    class="form-check-input"
                    type="checkbox"
                    disabled
                    :true-value="1"
                    :false-value="0"
                    v-model="transaction.status"
                    />
                </td>
                <td>
                    <input
                    class="form-check-input"
                    type="checkbox"
                    disabled
                    :true-value="1"
                    :false-value="0"
                    v-model="transaction.influencer"
                    />
                </td>
                <td>
                    <input
                    class="form-check-input"
                    type="checkbox"
                    :true-value="1"
                    disabled
                    :false-value="0"
                    v-model="transaction.reembolsada"
                    />
                </td>
             
                
                <td>{{ transaction.rtpUser }}%</td>
                <td>{{ transaction.rtpAgent }}%</td>

                <td>
                  {{
                    new Date(transaction?.created_at ?? "2023-01-23").toLocaleString()
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        
        </div>
        <div style="display: flex; justify-content: center; margin-top:20px" v-else>
                <div  class="alert alert-warning">Nenhuma transação encontrado</div>
        </div>

        <Paginator
          :first="1"
          v-if="transaction.length != 0"
          :currentPage="current_page"
          :rows="per_page"
          :totalRecords="total"
          @page="fetchData"
        ></Paginator>
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from "axios";
  import { useToast } from "vue-toast-notification";
  import Paginator from "primevue/paginator";
  export default {
    name: "TransactionPage",
    components: {
      Paginator
    },
    data() {
      return {
        current_page: 1,
        per_page: 10,
        total: 10,
        next: null,
        transaction: [],
        search: "",
      };
    },
    async mounted() {
      this.toast = useToast({ position: "top-right" });
      await this.getInfos();
    },
    methods: {
     
      pesquisar() {
        this.getInfos(1, this.search);
      },
      async handleChange(selectedValues) {
        this.selecionado = selectedValues;
        this.getInfos();
      },
      async getInfos(page = 1, search = "") {
        const token = localStorage.getItem("jwt-token");
        await axios
          .get(
            `${process.env.VUE_APP_API_URL}/api/agentes/transactions?page=${page}&search=${search}&agent=${JSON.stringify(this.selecionado)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.next = response.data.next_page_url;
            this.current_page = response.data.current_page - 1;
            this.per_page = response.data.per_page;
            this.total = response.data.total;
            this.transaction = response.data.data;
          
          })
          .catch(() => {
            this.toast.error(
              "Ocorreu um erro interno ao recuperar suas informações"
            );
          });
      },
      fetchData(page) {
        var pageA = page.page + 1;
        this.getInfos(pageA);
      },
    },
  };
  </script>
  