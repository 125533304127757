<template>
  <div class="container-login">
    <div class="content second-content">
      <div class="second-column">
        <h2 class="title title-second">Faça login</h2>
        <p class="description description-second">
           Use seu código do agente para login:
        </p>
        <form @submit.prevent="handleLogin" class="form" id="loginForm">
          <label class="label-input" for="">
            <i class="far fa-envelope icon-modify"></i>
            <input
              v-model="loginEmail"
              type="text"
              placeholder="Código do agente"
              required
              id="loginEmail"
            />
          </label>

          <label class="label-input" for="">
            <i class="fas fa-lock icon-modify"></i>
            <input
              v-model="loginPassword"
              :type="showVisible ? 'text' : 'password'"
              required
              placeholder="Senha"
              id="loginPassword"
            />
            <button
              @click="handleVisible"
              type="button"
              style="height: 100%; width: 44px; border: none"
            >
              <i
                style="display: flex; font-size: 23px"
                :class="showVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"
              ></i>
            </button>
          </label>

          <button class="btn btn-second" type="submit">Logar</button>

          
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { useToast } from "vue-toast-notification";

export default {
  name: "LoginView",
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      toast: null,
      showVisible: false,
    };
  },
  mounted() {
    this.toast = useToast({ position: "top-right" });
    if (localStorage.getItem("jwt-token") != null) {
      router.push("/");
    }
  },
  methods: {
    handleVisible() {
      this.showVisible = !this.showVisible;
    },

    async handleLogin() {
      await axios
        .post(process.env.VUE_APP_API_URL + "/api/agentes/auth/login", {
          agent_code: this.loginEmail,
          password: this.loginPassword,
        })
        .then((data) => {
          localStorage.setItem("jwt-token", data.data.access_token);
          window.location.href = "/";
        })
        .catch((error) => {
          this.toast.error(
            error.response.data.msg ?? "Ocorreu um erro interno"
          );
        });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
}
.password {
  margin-left: 10px;
  color: var(--color-primary) !important;
}
.container-login {
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--color-primary);
}
.content {
  background-color: #fff;
  border-radius: 15px;
  width: 700px;
  max-width: 90%;
  min-height: 350px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-transform: capitalize;
}
.title-primary {
  color: #fff;
}
.title-second {
  color: var(--color-primary);
}
.description {
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
}
.description-primary {
  color: #fff;
}
.description-second {
  color: #7f8c8d;
}
.btn {
  border-radius: 15px;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  padding: 10px 50px;
  cursor: pointer;
  font-weight: bold;
  width: 150px;
  align-self: center;
  border: none;
  margin-top: 1rem;
}
.btn-primary {
  background-color: var(--color-primary) !important;

  border: 1px solid #fff !important;
  transition: background-color 0.5s;
}
.btn-primary:hover {
  background-color: #fff;
  color: var(--color-secundary) !important;
}
.btn-second {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  transition: background-color 0.5s;
  color: #fff !important;
}
.btn-second:hover {
  background-color: #fff;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-secundary) !important;
}
.first-content {
  display: flex;
}
.first-content .second-column {
  z-index: 11;
}
.first-column {
  text-align: center;
  width: 40%;
  z-index: 10;
}
.second-column {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 338px;
  max-width: 80%;
}
.form input:focus-visible {
  outline: none;
}

.form input {
  height: 45px;
  border-radius: 9px;
  width: 100%;
  border: none;
  background-color: #ecf0f1;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ecf0f1 inset !important;
  -webkit-text-fill-color: #000 !important;
}
.label-input {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  margin: 8px;
  border-radius: 9px;
}
.icon-modify {
  color: #7f8c8d;
  padding: 0 5px;
}
</style>
