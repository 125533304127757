<template>
  <div>
    <Line style="height: 100%" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

// Registra os componentes necessários para o gráfico de linha
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

export default {
  name: "LineChart",
  components: { Line },
 
  props: {
    games: {
      type: Array,
      required: true,
    },
  },
  data() {
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--color-primary").trim();
    return {
      chartData: {
        labels: this.games.map((game) => game.game_name),
        datasets: [
          {
            label: "Visualizações",
            backgroundColor: primaryColor,
            borderColor: primaryColor,
            borderWidth: 1,
            data: this.games.map((game) => game.count), 
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          x: {
            ticks: {
              display: false, // Oculta os ticks do eixo Y
            },
            grid: {
              display: false, // Desabilita a grid do eixo X
            },
          },
          y: {
            ticks: {
              display: false, // Oculta os ticks do eixo Y
            },
            grid: {
              display: false, // Desabilita a grid do eixo Y
            },
            beginAtZero: true,
          },
        },
      },
    };
  },
};
</script>

<style scoped>
canvas {
  height: 300px !important;
}
</style>
