<template>
    <div class="login-layout">

      <router-view></router-view>
    </div>
  </template>
  
  <style scoped>
  .login-layout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; 
  }
  </style>
  
  <script>
  export default {
    name: 'AuthLayout',
  }
  </script>
  