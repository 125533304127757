import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/style/style.css";
import router from "./router";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import PrimeVue from "primevue/config";
import ptBr from "./assets/locale/pt-br.json";
import Aura from "@primevue/themes/aura";
import 'highlight.js/styles/monokai-sublime.css';
import axios from "axios";

var app = createApp(App);
app.use(router);
app.use(ElementPlus)
app.use(ToastPlugin);
app.use(VueSweetalert2);

app.use(PrimeVue, {
  locale: ptBr,
  zIndex: {
    overlay: 10000000000,
  },
  theme: {
    preset: Aura,
    options: {
      prefix: "p",
      darkModeSelector: ".e",
      cssLayer: false,
    },
  },
});
await axios
  .get(process.env.VUE_APP_API_URL + "/api/agentes/auth/details?url=" + window.location.hostname, {
    
})
.then((data) => {
  console.log(data)
  document.documentElement.style.setProperty("--color-primary", data.data.colorPrimary);
  document.documentElement.style.setProperty("--color-primary-clear", data.data.colorPrimaryClear);
  document.documentElement.style.setProperty("--color-text", data.data.colorText);
  document.documentElement.style.setProperty("--color-secundary", data.data.colorSecundary);
  document.documentElement.style.setProperty("--p-paginator-nav-button-selected-background", data.data.colorPrimary, "important");
  document.documentElement.style.setProperty("--p-paginator-nav-button-selected-color", data.data.colorSecundary, "important");

  app.mount("#app");

})
.catch(() => {
  document.body.innerHTML = `
    <div style="color: red; font-size: 20px; text-align: center; margin-top: 50px;">
      Esse site não existe
    </div>
  `;
 
});

