<template>
  <div class="m-0 p-0 w-100">
    <div class="row w-100" style="font-weight: 900">
      <div class="col">
        <h5>IP Whitelist</h5>
      </div>
      <div class="col text-right" style="color: var(--color-primary)">
        <div class="d-flex justify-content-end align-items-center">
          <i class="lni lni-spinner-arrow ml-2" style="font-weight: bold"></i>
          <p style="margin: 0 0 0 10px" @click="getInfos">Recarregar página</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4 p-0 w-100 bloco">
    <div class="row p-5">
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0 flex-grow-1" id="allIps" style="width: 100px">
          Total de IPs: ({{ ips.length }})
        </p>
        <button
          class="btnCreate"
          data-bs-toggle="modal"
          data-bs-target="#createIpModal"
        >
          <i class="lni lni-plus"></i>Cadastrar
        </button>
      </div>
      <hr class="mt-4 mb-4" />

      <div v-if="ips.length != 0" class="table-responsive p-0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>IP</th>
              <th>Data</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ip in ips" :key="ip.id">
              <td>{{ ip.ip }}</td>
              <td class="columnDate">
                {{ new Date(ip.created_at).toLocaleString() }}
              </td>
              <td class="columnExclude">
                <button @click="deleteIp(ip.id)" class="btnExcludeModal">
                  <i class="lni lni-trash-can"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
      <div style="display: flex; justify-content: center;" v-else>
              <div  class="alert alert-warning">Nenhum ip encontrado</div>
      </div>
    </div>
  </div>
  <CreateNewIp @getInfos="getInfos"></CreateNewIp>
</template>

<script>
import { useToast } from "vue-toast-notification";
import CreateNewIp from "./SubIp/CreateNewIp.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IpWhitelist",
  components: {
    CreateNewIp,
  },
  data() {
    return {
      ips: [],
    };
  },
  async mounted() {
    this.toast = useToast({ position: "top-right" });
    await this.getInfos();
  },
  methods: {
    async getInfos() {
      var token = localStorage.getItem("jwt-token");
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/agentes/ip", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.ips = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.toast.error(
            "Ocorreu um erro interno ao recuperar suas informações"
          );
        });
    },
    async deleteIp(id) {
      const result = await Swal.fire({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser desfeita!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        var token = localStorage.getItem("jwt-token");
        await axios
          .delete(process.env.VUE_APP_API_URL + "/api/agentes/ip/" + id, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            this.toast.success("Ip excluido com sucesso!");
            this.getInfos();
          })
          .catch(() => {
            this.toast.error("Ocorreu um erro interno ao deletar o ip");
          });
      }
    },
  },
};
</script>
<style></style>
