<template>
    <div class="m-0 p-0 w-100">
      <div class="row w-100" style="font-weight: 900">
        <div class="col">
          <h5>Gestão de jogos</h5>
        </div>
        <div class="col text-right" style="color: var(--color-primary)">
          <div
            @click="getInfos"
            class="d-flex justify-content-end align-items-center"
          >
            <i class="lni lni-spinner-arrow ml-2" style="font-weight: bold"></i>
            <p style="margin: 0 0 0 10px">Recarregar página</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 p-0 w-100 bloco">
      <div class="row p-5">
        <div class="container">
          <div class="d-flex-between alterarClasse">
            <p class="m-0 flex-grow-1" id="allAgents" style="width: 100px">
              Total de Games: ({{ games.length }})
            </p>
          </div>
        </div>
        <hr class="mt-4 mb-4" />
        <div class="d-flex justify-content-end align-items-center p-0">
          <el-select
          v-model="valueProvedor"
          multiple
          filterable
          default-first-option
          @change="handleChange('provedor', valueProvedor)"
          :reserve-keyword="false"
          placeholder="Filtrar por provedor"
          style="width: 240px; border-radius: 10px; margin-right: 10px;"
        >
          <el-option
            style="text-transform: capitalize;"
            v-for="item in provedores"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
          <div style="margin-left: 10px;" class="searchItem">
            <input
              type="text"
              @input="pesquisar"
              v-model="search"
              id="buscaItem"
              placeholder="Pesquisar..."
            />
          </div>
        </div>
        <div v-if="games != 0" class="table-responsive p-0 mt-4">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Código do jogo</th>
                <th>Status</th>
                <th>Provedor</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="game in games" :key="game.id">
                <td>{{ game.name }}</td>

                <td>{{ game.game_code }}</td>
                <td>
                  <input
                    class="form-check-input"
                    disabled
                    type="checkbox"
                    :true-value="1"
                    :false-value="0"
                    v-model="game.status"
                  />
                </td>
                <td style="text-transform: capitalize;">{{ game.provedor }}</td>
              
                
              </tr>
            </tbody>
          </table>
        </div>
        <div style="display: flex; justify-content: center;" v-else>
                <div  class="alert alert-warning">Nenhum game encontrado</div>
        </div>
        <Paginator
          :first="1"
          v-if="games.length != 0"
          :currentPage="current_page"
          :rows="per_page"
          :totalRecords="total"
          @page="fetchData"
        ></Paginator>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { useToast } from "vue-toast-notification";
  import Paginator from "primevue/paginator";
  import { ElSelect } from "element-plus";
  export default {
    name: "GamesPage",
    components: {
      Paginator,
      ElSelect,

    },
    data() {
      return {
        current_page: 1,
        per_page: 10,
        total: 10,
        next: null,
        games: [],
        search: "",
        options: [
        
        ],
        provedores: [],
        provedor: [],
      };
    },
    async mounted() {
      this.toast = useToast({ position: "top-right" });
      await this.getInfos();
    },
    methods: {
      pesquisar() {
        this.getInfos(1, this.search);
      },
  
      async handleChange(type, selectedValues) {
  
        this[type] = selectedValues;
        this.getInfos();
      },
      async getInfos(page = 1, search = "") {
        const token = localStorage.getItem("jwt-token");
        await axios
          .get(
            `${process.env.VUE_APP_API_URL}/api/agentes/games?page=${page}&search=${search}&provedor=${JSON.stringify(this.provedor)}&distribuidor=${JSON.stringify(this.distribuidor)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.next = response.data.next_page_url;
            this.current_page = response.data.current_page - 1;
            this.per_page = response.data.per_page;
            this.total = response.data.total;
            this.games = response.data.data;
            this.provedores = [];
            response.data.provedor.forEach((item) => {
              this.provedores.push({ value: item.id, label: item.name });
            });
          })
          .catch((error) => {
            console.log(error)
            this.toast.error(
              "Ocorreu um erro interno ao recuperar suas informações"
            );
          });
      },
      fetchData(page) {
        var pageA = page.page + 1;
        this.getInfos(pageA);
      },
    },
  };
  </script>
  